import React, { useState, useEffect } from 'react';
import { Container, Col, Row } from "react-bootstrap";
import { Link } from "gatsby"
import axios from 'axios';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import GetGGFXImage from '../common/site/get-ggfx-image';
import { ImageModule } from "../common/Image_Module"
import './assets/styles/_index.scss';

const SimilarProperties = (props) => {

    const [propItems, setPropItems] = useState(false)

    const getitems = async url => {
        try {
          const { data } = await axios.get(url, {})
          setPropItems(data)
        } catch (error) {
          console.error(error)
        }
    }

    useEffect(() => {
        let url = process.env.GATSBY_STRAPI_SRC + `/stb-lists/item/Property-details?pid=` +props?.propertyData?.id;
        getitems(url)
    },[])

    // Slider settings
    let settings = {
        dots: false,
        arrows: false,
        infinite: false,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: false,
                arrows: false,
                dots: false,
                },
            },
            {
                breakpoint: 992,
                settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: false,
                arrows: false,
                dots: true,
                },
            },
            {
                breakpoint: 767,
                settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: false,
                arrows: false,
                dots: true,
                },
            },
        ],
    }
    // Slider settings

    return (
        <>
            {
                propItems && propItems.length > 0 &&
                <section className="similar-properties-wrapper">
                    <Container>
                        <Row>
                            <Col>
                                <h2 className="similar-heading">Similar Properties</h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Slider className="similar-properties-slider" {...settings}>
                                    {
                                        propItems && propItems.map((item, key) => {

                                            let processedImages = JSON.stringify({});
                                            if (item?.imagetransforms?.images_Transforms) {
                                                processedImages = item.imagetransforms.images_Transforms;
                                            }

                                            let propertyUrl = (item.search_type === "sales" ? "/property-for-sale/" : "/property-for-rent/")+item.slug+"-"+item.id+"/";

                                            return (
                                                <div className="similar-properties-slide">
                                                    <div className="similar-properties-img-zoom">
                                                        <Link to={propertyUrl}>
                                                            <ImageModule
                                                                imagename={"property.images.featuredProperties"}
                                                                ImageSrc={item?.images[0]}
                                                                ggfx_results={item?.ggfx_results}
                                                                altText={item?.display_address}
                                                                strapi_id={item?.id}
                                                            />
                                                        </Link>
                                                    </div>
                                                    <div className="similar-properties-text-wrapper">
                                                        {
                                                            item?.description &&
                                                            <p className="display-address display-desc">{item?.description}</p>
                                                        }
                                                        {
                                                            item?.price &&
                                                            <div className="price-details">{item?.price.toLocaleString()} {item?.currency}</div>
                                                        }
                                                        {
                                                            item?.display_address &&
                                                            <p className="display-address">{item?.display_address}</p>
                                                        }
                                                        {
                                                            item?.area &&
                                                            // <div className="location-details d-flex align-items-center">
                                                            <div className="location-details d-flex align-items-center">
                                                                <i className="icon icon-map-pin"></i>
                                                                <span>{item?.area}</span>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </Slider>
                            </Col>
                        </Row>
                    </Container>
                </section>
            }
        </>
    )
}

export default SimilarProperties